import { IHash, DateTimePeriodDto, UidGenerator, OutlookRecipientTypeEnum, convertToNetTicks, OutlookRecipientDto, ExchangeResourceTypeEnum, OutlookAppointmentDto } from "booking-app-op";
import { DateTimePeriod } from "./DateTimePeriod";
import { OutlookRecipient } from "./OutlookRecipient";

export class OutlookAppointment {
    private _minVersionPlatform: number = 1.8;
    private _uid: string;
    private _customUid: string;
    private _globalId: string;
    private _guid?: string;
    private _haveOrder: boolean;
    private _haveNormalOrder: boolean;
    private _startInTicks: number;
    private _endInTicks: number;
    private _start: Date;
    private _end: Date;
    private _subject: string;
    private _requiredRecipients: Array<OutlookRecipient>;
    private _requiredRecipientsMap: IHash<OutlookRecipient>;
    private _optionalRecipients: Array<OutlookRecipient>;
    private _optionalRecipientsMap: IHash<OutlookRecipient>;
    private _resources: Array<OutlookRecipient>;
    private _resourcesMap: IHash<OutlookRecipient>;
    private _organizerEmail?: string;
    private _organizerInRequiredAttendees: OutlookRecipient | undefined;
    private _isRecurringMaster: boolean;
    private _isNoEndDate: boolean;
    private _isOccurence?: boolean;
    private _seriesId?: string;
    private readonly _occurrences: Array<DateTimePeriod>;
    private _itemId: string;
    private _originalStart: string;
    private _originalEnd: string;
    private _recurringAppDuration: string;
    private _isReOpenAfterSent: boolean;
    // private _applicationSettingsService: IApplicationSettingsService;


    private _sessionId: string;

    public set IsReOpenAfterSent(isReopenAfterSent: boolean){
        this._isReOpenAfterSent = isReopenAfterSent;
    }

    public get IsReOpenAfterSent() {
        return this._isReOpenAfterSent;
    }

    public get RecurringAppDuration() {
        return this._recurringAppDuration;
    }
    public set RecurringAppDuration(value: string) {
        this._recurringAppDuration = value;
    }
    public get OriginalEnd() {
        return this._originalEnd;
    }
    public set OriginalEnd(value: string) {
        this._originalEnd = value;
    }
    public get OriginalStart() {
        return this._originalStart;
    }
    public set OriginalStart(value: string) {
        this._originalStart = value;
    }
    public get ItemId() {
        return this._itemId;
    }
    public set ItemId(itemId: string) {
        this._itemId = itemId;
    }
    public get Uid() {
        return this._uid;
    }
    public set Uid(uid: string) {
        this._uid = uid;
    }
    public get CustomUid() {
        return this._customUid;
    }
    public set CustomUid(customUid: string) {
        this._customUid = customUid;
    }
    public get GlobalId() {
        return this._globalId;
    }
    public set GlobalId(globalId: string) {
        this._globalId = globalId;
    }
    public get Guid() {
        return this._guid;
    }
    public set Guid(guid: string | undefined) {
        this._guid = guid;
    }
    public get RequiredRecipients(): Array<OutlookRecipient> {
        return this._copyRecipients(this._requiredRecipients);
    }
    public get OptionalRecipients(): Array<OutlookRecipient> {
        return this._copyRecipients(this._optionalRecipients);
    }
    public get Resources(): Array<OutlookRecipient> {
        return this._copyRecipients(this._resources);
    }
    public get Recipients(): Array<OutlookRecipient> {
        let recipients: Array<OutlookRecipient> = this.RequiredRecipients;
        recipients = recipients.concat(this.OptionalRecipients);
        if (this.Resources && this.Resources.length > 0) {
            this.Resources.forEach((rs) => {
                recipients = recipients.filter(
                    (t) => t.Email.toLowerCase() != rs.Email.toLowerCase()
                );
            });
        }
        recipients = recipients.concat(this.Resources);
        return recipients;
    }
    public get Occurrences(): Array<DateTimePeriodDto> {
        let occurrences: DateTimePeriodDto[] = [];
        this._occurrences.forEach((item: DateTimePeriod) => {
            occurrences.push({
                StartTicks: item.StartTicks,
                EndTicks: item.EndTicks,
                Start: item.Start,
                End: item.End,
                StartInOrgTz: item.StartInOrgTz,
                EndInOrgTz: item.EndInOrgTz
            });
        });
        return occurrences;
    }
    public set SeriesId(value: string | undefined) {
        this._seriesId = value;
    }
    public get SeriesId(): string | undefined {
        return this._seriesId;
    }
    public set Occurrences(occurrences: Array<DateTimePeriodDto>) {
        // empty the recurring dates list.
        this._occurrences.length = 0;
        if (!occurrences || occurrences.length == 0) return;
        occurrences.forEach((dto: DateTimePeriodDto) => {
            this._occurrences.push({
                StartTicks: dto.StartTicks,
                EndTicks: dto.EndTicks,
                Start: dto.Start,
                End: dto.End,
                StartInOrgTz: dto.StartInOrgTz,
                EndInOrgTz: dto.EndInOrgTz
            });
        });
    }
    public get IsRecurringMaster(): boolean {
        return this._isRecurringMaster;
    }
    public set IsRecurringMaster(value: boolean) {
        this._isRecurringMaster = value;
    }
    public get IsNoEndDate(): boolean {
        return this._isNoEndDate;
    }
    public set IsNoEndDate(value: boolean) {
        this._isNoEndDate = value;
    }
    public get IsOccurence(): boolean | undefined {
        return this._isOccurence;
    }
    public set IsOccurence(value: boolean | undefined) {
        this._isOccurence = value;
    }

    public get HaveOrder(): boolean {
        return this._haveOrder;
    }
    public set HaveOrder(value: boolean) {
        this._haveOrder = value;
    }
    public get HaveNormalOrder(): boolean {
        return this._haveNormalOrder;
    }
    public set HaveNormalOrder(value: boolean) {
        this._haveNormalOrder = value;
    }
    constructor() {
        this._startInTicks = 0;
        this._endInTicks = 0;
        this._subject = "";
        this._requiredRecipients = [];
        this._requiredRecipientsMap = {};
        this._optionalRecipients = [];
        this._optionalRecipientsMap = {};
        this._resources = [];
        this._resourcesMap = {};
        this._uid = this._customUid = UidGenerator.NewUid();
        //this._uid = this._customUid = '';
        this._sessionId = UidGenerator.NewUid();
        this._isRecurringMaster = false;
        this._isNoEndDate = false;
        this._occurrences = [];
        this._itemId = "";
        this._globalId = "";
        this._originalStart = "";
        this._originalEnd = "";
        this._recurringAppDuration = "";
        // this._applicationSettingsService = container.resolve(TokenIApplicationSettingsService);
        this._haveOrder = false;
        this._haveNormalOrder = false;
        this._start = new Date();
        this._end = new Date();
        this._isReOpenAfterSent = false;
    }

    public set OrganizerEmail(organizerEmail: string | undefined) {
        if (organizerEmail) {
            this._organizerEmail = organizerEmail.toLowerCase();
        }
    }

    public get OrganizerEmail() {
        return this._organizerEmail;
    }

    private _setOptionalRecipients(recipients: Array<OutlookRecipient>) {
        this._emptyOptionalRecipients();
        if (recipients != null && recipients.length > 0) {
            recipients.forEach((rpt) => {
                this._pushOptionalRecipients(rpt);
            });
        }
    }
    private _setRequiredRecipients(recipients: Array<OutlookRecipient>) {
        this._emptyRequiredRecipients();
        if (recipients != null && recipients.length > 0) {
            recipients.forEach((rpt) => {
                this._pushRequiredRecipients(rpt);
            });
        }
    }

    private _pushRequiredRecipients(recipient: OutlookRecipient) {
        // ignore organizer
        if (
            recipient.Email &&
            recipient.Email !== "" &&
            !this._requiredRecipientsMap[recipient.Email] 
            && recipient.Email.toLowerCase() != this._organizerEmail?.toLowerCase()
        ) {
            recipient.RecipientType = OutlookRecipientTypeEnum.Required;
            this._requiredRecipientsMap[recipient.Email] = recipient;
            this._requiredRecipients.push(recipient);
        }
    }
    private _pushResources(recipient: OutlookRecipient) {
        // ignore organizer
        if (
            recipient.Email &&
            recipient.Email !== "" &&
            !this._resourcesMap[recipient.Email] 
             &&recipient.Email.toLowerCase() != this._organizerEmail?.toLowerCase()
        ) {
            recipient.RecipientType = OutlookRecipientTypeEnum.Resources;
            this._resourcesMap[recipient.Email] = recipient;
            this._resources.push(recipient);
        }
    }
    private _pushOptionalRecipients(recipient: OutlookRecipient) {
        if (
            recipient.Email &&
            recipient.Email !== "" &&
            !this._optionalRecipientsMap[recipient.Email]
            //&& recipient.Email.toLowerCase() != this._organizerEmail?.toLowerCase()
        ) {
            recipient.RecipientType = OutlookRecipientTypeEnum.Optional;
            this._optionalRecipientsMap[recipient.Email] = recipient;
            this._optionalRecipients.push(recipient);
        }
    }
    private _emptyRequiredRecipients() {
        this._requiredRecipients = [];
        this._requiredRecipientsMap = {};
    }
    private _emptyOptionalRecipients() {
        this._optionalRecipients = [];
        this._optionalRecipientsMap = {};
    }
    private _copyRecipients(
        recipients: Array<OutlookRecipient>
    ): OutlookRecipient[] {
        return recipients.map((rpt) => 
        Object.create(rpt)
        );
    }

    GetResource(recipient: OutlookRecipient): OutlookRecipient | undefined {
        // ignore organizer
        if (
            recipient.Email &&
            recipient.Email !== "" &&
            this._resourcesMap[recipient.Email] 
            && recipient.Email.toLowerCase() != this._organizerEmail?.toLowerCase()
        ) {
            return this._resourcesMap[recipient.Email];
        }
        return undefined;
    }

    UpdateSubject(subject: string): boolean {
        let isUpdated = false;
        if (this._subject !== subject) {
            this._subject = subject;
            isUpdated = true;
        }
        return isUpdated;
    }
    UpdateStartTime(start: Date): boolean {
        let isUpdated = false;
        let newStartTime = convertToNetTicks(start);
        if (newStartTime !== this._startInTicks) {
            this._startInTicks = newStartTime;
            this._start = start;
            isUpdated = true;
        }
        return isUpdated;
    }
    UpdateEndTime(end: Date) {
        let isUpdated = false;
        let newEndTime = convertToNetTicks(end);
        if (newEndTime !== this._endInTicks) {
            this._endInTicks = newEndTime;
            this._end = end;
            isUpdated = true;
        }
        return isUpdated;
    }
    AddRecipients(resources: Array<OutlookRecipientDto>): OutlookRecipient[] {
        let addedResources: OutlookRecipient[] = [];
        let self = this;
        if (resources && resources.length > 0) {
            resources.forEach((rs) => {
                let recipient = new OutlookRecipient(
                    rs.Email,
                    rs.Name,
                    undefined,
                    rs.ResourceType
                );
                if (
                    !self._requiredRecipientsMap[recipient.Email] &&
                    !self._optionalRecipientsMap[recipient.Email] &&
                    !self._resourcesMap[recipient.Email]
                ) {
                    if (this._isPlatformSupported()) {
                        if (recipient.ResourceType == ExchangeResourceTypeEnum.Room) {
                            self._pushResources(recipient);
                        }
                    } else {
                        self._pushResources(recipient);
                    }

                    addedResources.push(recipient);
                }
            });
        }
        return addedResources;
    }

    AddRequiredAttendee(
        requiredAttendee: Array<OutlookRecipientDto>
    ): OutlookRecipient[] {
        let addedRequiredAttendees: OutlookRecipient[] = [];
        let self = this;
        if (requiredAttendee && requiredAttendee.length > 0) {
            requiredAttendee.forEach((rs) => {
                let recipient = new OutlookRecipient(rs.Email, rs.Name);
                if (
                    !self._requiredRecipientsMap[recipient.Email] &&
                    !self._optionalRecipientsMap[recipient.Email] &&
                    !self._resourcesMap[recipient.Email]
                ) {
                    self._pushRequiredRecipients(recipient);
                    addedRequiredAttendees.push(recipient);
                }
            });
        }
        return addedRequiredAttendees;
    }

    RemoveRecipients(resourceEmails: Array<string>) {
        let self = this;
        if (resourceEmails && resourceEmails.length > 0) {
            resourceEmails.forEach((email) => {
                if (self._requiredRecipientsMap[email]) {
                    delete self._requiredRecipientsMap[email];
                    self._requiredRecipients = self._requiredRecipients.filter(
                        (rs) => rs.Email !== email
                    );
                } else if (self._optionalRecipientsMap[email]) {
                    delete self._optionalRecipientsMap[email];
                    self._optionalRecipients = self._optionalRecipients.filter(
                        (rs) => rs.Email !== email
                    );
                } else if (self._resourcesMap[email]) {
                    delete self._resourcesMap[email];
                    self._resources = self._resources.filter((rs) => rs.Email !== email);
                }
            });
        }
    }
    UpdateRequiredRecipients(
        requiredRecipients: Array<OutlookRecipient>
    ): boolean {
        let isUpdated = false;
        // add resources from custom properties to required recipients.
        let currentRequiredMap: IHash<OutlookRecipient> = {};
        let currentRequiredRecipients: OutlookRecipient[] = [];

        requiredRecipients.forEach((rs) => {
            // ignore organizer when calculate required recipients.
            if (rs.Email && rs.Email !== "") {
                if (rs.Email != this._organizerEmail) {
                    if (!currentRequiredMap[rs.Email]) {
                        currentRequiredMap[rs.Email] = rs;
                        currentRequiredRecipients.push(rs);
                    }
                }
                else {
                    this._organizerInRequiredAttendees = rs;
                }
            }
        });
        // delete all resource that no longer exsited in new list.
        let deletedRequired = this._requiredRecipients.filter(
            (rs) => !currentRequiredMap[rs.Email]
        );
        if (deletedRequired.length > 0) {
            deletedRequired.forEach((deletedRs) => {
                delete this._requiredRecipientsMap[deletedRs.Email];
            });
            isUpdated = true;
        }
        // keep all resources still existed in new list.
        this._requiredRecipients = this._requiredRecipients.filter(
            (rs) => currentRequiredMap[rs.Email]
        );
        // add all resources that added in new list.
        let addedRequired: OutlookRecipient[] = [];
        if (currentRequiredRecipients != null) {
            addedRequired = currentRequiredRecipients.filter(
                (rs) => !this._requiredRecipientsMap[rs.Email]
            );
        }
        if (addedRequired.length > 0) {
            addedRequired.forEach((addedRs) => {
                let recipient = new OutlookRecipient(addedRs.Email, addedRs.Name);
                this._pushRequiredRecipients(recipient);
            });
            isUpdated = true;
        }
        return isUpdated;
    }
    get OrganizerInRequiredAttendees(): OutlookRecipient | undefined {
        return this._organizerInRequiredAttendees;
    }
    UpdateOptionalAttendees(
        optionalRecipients: Array<OutlookRecipient>
    ): boolean {
        let isUpdated = false;
        let currentOptionalMap: IHash<OutlookRecipient> = {};
        let currentOptionalRecipients: OutlookRecipient[] = [];
        if (optionalRecipients != null) {
            optionalRecipients.forEach((rs) => {
                if (!currentOptionalMap[rs.Email]) {
                    currentOptionalMap[rs.Email] = rs;
                    currentOptionalRecipients.push(rs);
                }
                if (rs.Email && rs.Email !== "" && rs.Email != this._organizerEmail) {

                }
            });
        }
        // delete all resource that no longer exsited in new list.
        let deletedOptional = this._optionalRecipients.filter(
            (rs) => !currentOptionalMap[rs.Email]
        );
        if (deletedOptional.length > 0) {
            deletedOptional.forEach((deletedRs) => {
                delete this._optionalRecipientsMap[deletedRs.Email];
            });
            isUpdated = true;
        }
        // keep all resources still existed in new list.
        this._optionalRecipients = this._optionalRecipients.filter(
            (rs) => currentOptionalMap[rs.Email]
        );
        // add all resources that added in new list.
        let addedOptional: OutlookRecipient[] = [];
        if (currentOptionalRecipients != null) {
            addedOptional = currentOptionalRecipients.filter(
                (rs) => !this._optionalRecipientsMap[rs.Email]
            );
        }
        if (addedOptional.length > 0) {
            addedOptional.forEach((addedRs) => {
                let recipient = new OutlookRecipient(addedRs.Email, addedRs.Name);
                this._optionalRecipients.push(recipient);
                this._optionalRecipientsMap[recipient.Email] = recipient;
            });
            isUpdated = true;
        }
        return isUpdated;
    }
    public UpdateRecipients(recipients: OutlookRecipientDto[]): boolean {
        let isUpdated = false;
        let requiredRecipients: OutlookRecipient[] = [];
        let optionalRecipients: OutlookRecipient[] = [];
        let newRecipientsMap: IHash<OutlookRecipient> = {};
        if (recipients != null && recipients.length > 0) {
            recipients.forEach((dto) => {
                if (dto.Email && dto.Email !== "") {
                    let recipientType = dto.RecipientType ? dto.RecipientType : 0;
                    let olRecipient = new OutlookRecipient(
                        dto.Email,
                        dto.Name,
                        recipientType
                    );
                    if (this._isOptionalRecipients(dto.Email)) {
                        if (dto.Name == null) {
                            let oldAttendee = this._optionalRecipientsMap[dto.Email];
                            dto.Name = oldAttendee.Name;
                        }
                        optionalRecipients.push(olRecipient);
                    } else {
                        let oldAttendee = this._requiredRecipientsMap[dto.Email];
                        if (oldAttendee) {
                            if (!olRecipient.Name || olRecipient.Name === "") {
                                if (!dto.Name) {
                                    olRecipient.Name = oldAttendee.Name;
                                }
                            }
                        } else {
                            isUpdated = true;
                        }
                        requiredRecipients.push(olRecipient);
                    }
                    newRecipientsMap[olRecipient.Email] = olRecipient;
                }
            });
        }
        let isOptionalRemoved = this._optionalRecipients.some(
            (r) => !newRecipientsMap[r.Email]
        );
        let isRequiredRemoved = this._requiredRecipients.some(
            (r) => !newRecipientsMap[r.Email]
        );
        if (!isUpdated) {
            isUpdated = isOptionalRemoved || isRequiredRemoved;
        }
        this._setOptionalRecipients(optionalRecipients);
        this._setRequiredRecipients(requiredRecipients);
        return isUpdated;
    }
    private _concatResourcesFromCustomProperties(
        recipients: Array<OutlookRecipient>
    ) {
        let fullRecipients: OutlookRecipient[] = [];
        if (!recipients) return fullRecipients;
        fullRecipients = fullRecipients.concat(recipients);
        if (this._resources) {
            fullRecipients = fullRecipients.concat(this._resources);
        }
        return fullRecipients;
    }
    public UpdateResources(resources: Array<OutlookRecipient>): boolean {
        let isUpdated = false;
        // add resources from custom properties to required recipients.
        let newResourceMap: IHash<OutlookRecipient> = {};
        let currentResources: OutlookRecipient[] = [];
        if (resources != null) {
            resources.forEach((rs) => {
                // ignore organizer when calculate required recipients.
                if (rs.Email && rs.Email !== "" && rs.Email != this._organizerEmail) {
                    if (!newResourceMap[rs.Email]) {
                        newResourceMap[rs.Email] = rs;
                        currentResources.push(rs);
                    }
                }
            });
        }
        // delete all resource that no longer exsited in new list.
        let deletedResources = this._resources.filter(
            (rs) => !newResourceMap[rs.Email]
        );
        if (deletedResources.length > 0) {
            deletedResources.forEach((deletedRs) => {
                delete this._resourcesMap[deletedRs.Email];
            });
            isUpdated = true;
        }
        // keep all resources still existed in new list.
        this._resources = this._resources.filter((rs) => newResourceMap[rs.Email]);
        // add all resources that added in new list.
        let addedResources: OutlookRecipient[] = [];
        if (currentResources != null) {
            addedResources = currentResources.filter(
                (rs) => !this._resourcesMap[rs.Email]
            );
        }
        if (addedResources.length > 0) {
            addedResources.forEach((addedRs) => {
                let recipient = new OutlookRecipient(addedRs.Email, addedRs.Name);
                this._pushResources(recipient);
            });
            isUpdated = true;
        }
        return isUpdated;
    }
    private _isOptionalRecipients(email: string): boolean {
        return this._optionalRecipientsMap[email] != null;
    }

    Export(): OutlookAppointmentDto {
        let dto: OutlookAppointmentDto =
        {
            Uid: this._uid,
            StartTimeTicks: this._startInTicks,
            EndTimeTicks: this._endInTicks,
            StartTime: this._start,
            EndTime: this._end,
            Subject: this._subject,
            SessionId: this._sessionId,
            Guid: this._guid,
            GlobalId: this._globalId,
            IsRecurringMaster: this._isRecurringMaster,
            IsNoEndDate: this._isNoEndDate,
            Occurrences: this.Occurrences,
            ItemId: this._itemId,
            IsOccurence: this._isOccurence,
            RecurringAppDuration: this._recurringAppDuration,
            OriginalEnd: this._originalEnd,
            OriginalStart: this._originalStart,
            HaveOrder: this._haveOrder,
            HaveNormalOrder: this._haveNormalOrder,
            IsReopenAfterSent: this._isReOpenAfterSent
        } as OutlookAppointmentDto
        return dto;
    }
    IsNewBooking(): boolean {
        return this._globalId == null || this._globalId == "";
    }
    ExportRecipients(): OutlookRecipientDto[] {
        let resourceDtos = this.Recipients.map((rs) => {
            let rsDto: OutlookRecipientDto =
            {
                Email: rs.Email,
                Name: rs.Name,
                RecipientType: rs.RecipientType
            }
            return rsDto;
        });
        return resourceDtos;
    }

    private _isPlatformSupported(): boolean {
        return Office.context.requirements.isSetSupported(
            "Mailbox",
            this._minVersionPlatform
        );
    }

    public get IsPlatformSupported(): boolean {
        return this._isPlatformSupported();
    }
}
