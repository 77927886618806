import "reflect-metadata";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { container } from "tsyringe";
import { BookingOPAddin, IAuthenticationService, TokenIAuthenticationService, IConfiguration } from "booking-app-op";
import { RegisterOfficeServices } from "../../ioc";
import {
  INestedAppAuthenticationService,
  TokenINestedAppAuthenticationService,
} from "../../authentication/INestedAppAuthenticationService";

RegisterOfficeServices(container);

const RFLauncher = () => {
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [isSupported, setIsSupported] = React.useState(true);

  React.useEffect(() => {
    setIsInitialized(true);
  }, [0]);

  return isSupported ? isInitialized ? <BookingOPAddin /> : <div></div> : <div>error</div>;
};

const rootElement: HTMLElement = document.getElementById("container");

/* Render application after Office initializes */

if (rootElement) {
  // const application = createRoot(portalDiv);

  if (typeof Office === "undefined") {
    // cannot load Office.js because of slow networks
    createRoot(rootElement).render(<div>Error</div>);
  } else {
    Office.onReady(async function () {
      const nestedAppAuthenticationService: INestedAppAuthenticationService = container.resolve(
        TokenINestedAppAuthenticationService
      );
      const accountType = Office.context.mailbox.userProfile.accountType;
      const loginHint =
        accountType === "office365" || accountType === "outlookCom"
          ? Office.context.mailbox.userProfile.emailAddress
          : "";

      const configuration: IConfiguration = {
        id: "55d7cabc-cb78-4288-9196-27a7de163977",
        tenantId: "8fe418f8-5aeb-46bb-bfcb-9a61c6b07538",
        applicationIdUri: "api://add-on-company.com/32ea7b9c-ddb4-489d-bd24-286403992e86",
        callBackUrl: "https://add-on-company.com",
        clientId: "32ea7b9c-ddb4-489d-bd24-286403992e86",
      };

      await nestedAppAuthenticationService.initialize(
        loginHint,
        configuration.clientId,
        configuration.tenantId,
        configuration.callBackUrl
      );

      const authenticationService: IAuthenticationService = container.resolve(TokenIAuthenticationService);

      await authenticationService.GetAccessTokenFromAAD(configuration).then((isAuthenticated) => {
        if (!isAuthenticated) {
          createRoot(rootElement).render(
            <div>Incorrect configuration in External Authentication</div>
          );
        } else {
          createRoot(rootElement).render(<RFLauncher />);
        }
      });
    });
  }
}

// if ((module as any).hot) {
//   (module as any).hot.accept("./components/App", () => {
//     const NextApp = require("./components/App").default;
//     root.render(NextApp);
//   });
// }
