import { LanguageConverter } from "booking-app-op";

export class OutlookSettingsRepository {
  SetAppSetting(key: string, value: any): boolean {
    let isSuccess = false;
    try {
      if (Office.context && Office.context.roamingSettings) {
        Office.context.roamingSettings.set(key, value);
        Office.context.roamingSettings.saveAsync();
        isSuccess = true;
      }
    } catch (ex) {
      //console.log(ex);
    }
    return isSuccess;
  }
  GetAppSetting(key: string): any {
    let value = "";
    try {
      if (Office.context && Office.context.roamingSettings) {
        value = Office.context.roamingSettings.get(key);
      }
    } catch (ex) {
      //console.log(ex);
    }
    return value;
  }
  GetOutlookLanguage(): string {
    let language = "";
    try {
      if (Office.context) {
        language = LanguageConverter.Map(Office.context.displayLanguage);
      }
    } catch (ex) {
      //console.log(ex);
    }
    return language;
  }
  GetTimeZoneOffset(): number {
    let tzOffset = 0;
    try {
      if (Office.context && Office.context.mailbox) {
        tzOffset = Office.context.mailbox.convertToLocalClientTime(new Date()).timezoneOffset;
      }
    } catch (ex) {
      //console.log(ex);
    }
    return tzOffset;
  }
  NotificationMessages(id: string, details: any, handleResult: any): void {
    if (Office.context && Office.context.mailbox && Office.context.mailbox.item) {
      Office.context.mailbox.item.notificationMessages.addAsync(id, details, handleResult);
    }
  }
  GetOutlookTheme(): string {
    try {
      if (Office.context && Office.context.officeTheme) {
        const office: any = Office.context.officeTheme;
        const themeId = office?.themeId || "#000003";

        return themeId;
      }
    } catch (ex) {
      console.log("error", ex);
    }
    return "#000003";
  }
}
